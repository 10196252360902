import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function PWAInstallPrompt() {
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [isInstalled, setIsInstalled] = useState(false);

  useEffect(() => {
    // Listen for the 'beforeinstallprompt' event to capture the deferred prompt
    console.log("useEffect beforeinstallprompt")
    const handleBeforeInstallPrompt = (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
    };

    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);

    // Check if the PWA is already installed
    window.addEventListener("appinstalled", () => {
      setIsInstalled(true);
    });

    return () => {
      window.removeEventListener("beforeinstallprompt", handleBeforeInstallPrompt);
    };
  }, []);

  const installApp = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          toast.success("App installation successful!", {
            autoClose: 3000, // Auto-close after 3 seconds
          });
          setIsInstalled(true); // Set the state to indicate installation
        }
        setDeferredPrompt(null);
      });
    }
  };

  // Conditionally render the component based on whether the PWA is installed
  return !isInstalled ? (
    <div>
      <button onClick={installApp}>Install App</button>
      <ToastContainer />
    </div>
  ) : null;
}

export default PWAInstallPrompt;
